import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import moment from "moment";
import "moment/locale/fr";
import {isoFormat, getWeekday} from "utils/Utils";

import PersonTypeBadge from "components/Backend/common/PersonTypeBadge";
import "./ManageeLine.scss";
import ManageeDay from "./ManageeDay";

const ManageeLine = (props) => {
    const {
        user,
        hasManagees,
        manager,
        // isManagerOpen,
        level,
        me,
        holidays,
        // openManagers,
        openManager,
        closeManager,
        toggleManager,
        daysArray,
    } = props;
    const openManagers = useSelector(
        (state) => state.teamPlanning.openManagers
    );

    const displayCounters = useSelector(state => state.timesheet.displayPlanningCounters)
    const isMobile = useSelector((state) => state.common.isMobile)

    const isFirstLevel = user.manager === me.matricule;
    const isManagerOpen = openManagers.includes(manager.matricule);
    const [isVisible, setVisible] = useState(
        isManagerOpen || isFirstLevel ? true : false
    );
    const displayWeekend = useSelector(state => state.timesheet.displayWeekend)

    useEffect(() => {
        setVisible(isManagerOpen || isFirstLevel);
    }, [isManagerOpen, isFirstLevel]);

    const getAbsencesForDay = (day) => {
        let events = [];

        user.absences.map((element) => {
            if (
                isoFormat(element.startAt) <= isoFormat(day) &&
                isoFormat(element.endAt) >= isoFormat(day)
            ) {
                events.push(element);
            }
        });

        return events;
    };

    const getStyles = (element) => {
        const day = {
            am: null,
            pm: null,
            remoteAm: null,
            remotePm: null
        }

        let absencesForDay = getAbsencesForDay(element);
        let date = isoFormat(element);
        const remoteData = user.remoteDays.find(day => day.day === date);

        if (remoteData) {
            day.remoteAm = remoteData.am
            day.remotePm = remoteData.pm
        }

        if (
            getWeekday(element) === 6 || // Saturday
            getWeekday(element) === 0 || // Sunday
            holidays.includes(date) ||
            (user.endAt && date > isoFormat(user.endAt))
        ) {
            day.am = "weekend"
            day.pm = "weekend"
        } else if (absencesForDay.length > 0) {
            absencesForDay.map(absence => {
                if (isoFormat(absence.startAt) < date &&  isoFormat(absence.endAt) > date) {
                    day.am = day.pm = absence.state === "submitted" ? "submitted" : "validated"
                } else
                {
                    if (isoFormat(absence.startAt) === date && (absence.startMoment === "pm" || absence.endMoment === "pm")) {
                        day.pm = absence.state === "submitted" ? "submitted" : "validated"
                    }
                    if (isoFormat(absence.endAt) === date && (absence.endMoment === "am" || absence.startMoment === "am")) {
                        day.am = absence.state === "submitted" ? "submitted" : "validated"
                    }
                    if (isoFormat(absence.startAt) === date && (absence.startMoment === "am")) {
                        day.am = absence.state === "submitted" ? "submitted" : "validated"
                    }
                    if (isoFormat(absence.endAt) === date && (absence.endMoment === "pm")) {
                        day.pm = absence.state === "submitted" ? "submitted" : "validated"
                    }
                }
            })

        }
        return day;
    };

    const getLevelClass = () => {
        return "level-"+level
    };

    const getClasses = () => {
        const classes = ["managee-line"]
        classes.push(isVisible ? "visible-row" : "invisible-row")
        classes.push(getLevelClass())
        if (!displayCounters) {
            classes.push("without-counters")
        }

        return classes.join(" ")
    }

    const toggle = hasManagees && isMobile ? {
        onClick: () => toggleManager(user.matricule)
    } : {}

    return (
            <div
                className={getClasses()}
                title={user.identity}
            >
                <div className="managee-container" {...toggle}>
                    <div className="managee-identity-container">
                        {/* <IdentityBlock user={user} /> */}
                        <PersonTypeBadge personType={user.personType}/>
                        <div className="managee-identity-label">
                            {user.identity}
                        </div>
                    </div>
                    {hasManagees && !isMobile &&
                        (openManagers.includes(user.matricule) ? (
                            <span
                                onClick={() => closeManager(user.matricule)}
                                className="open-manager-indicator fal fa-chevron-down fa-fw open-group"
                            ></span>
                        ) : (
                            <span
                                onClick={() => openManager(user.matricule)}
                                className="open-manager-indicator fal fa-chevron-down fa-fw closed-group"
                            ></span>
                        ))}
                    {!user.isContractor() && displayCounters && <div className="absence-counters-line">
                        <span className="absence-counter-first-block">
                            CP : <span className="absence-counter">{(user.cp + user.beforeJune).toFixed(2)}</span>{" "}
                            dont{" "}
                            <span className="before-june-counter">
                                {user.beforeJune.toFixed(2)}
                            </span>{" "}
                            avant le 31/05
                        </span>
                        <span className="absence-counter-second-block">
                            RTT :{" "}
                            <span className="absence-counter">{user.rtt.toFixed(2)}</span>
                        </span>
                         {user.displayWorkedDays && <div className="worked-days" title="Projection de jours travaillés au 31/12">
                            <i className="fal fa-calendar-exclamation"></i> {user.workedDays} J</div>}
                    </div>}
                </div>
                <div className={"planning-days-container "+(displayWeekend ? "" : "without-weekends")}>
                    {daysArray.map((element, key) => {
                        return (
                            <ManageeDay day={getStyles(element)} key={key}/>
                        );
                    })}
                </div>
            </div>
    );
};

export default ManageeLine;
