import React, {useEffect, useState} from "react";
import moment from "moment";
import "moment/locale/fr";
import {motion, AnimatePresence} from 'framer-motion/dist/framer-motion'
import {
    EntityRepository,
    EVENT_CODE_REPOSITORY,
} from "store/EntityRepository";

import PageHeader from "../../common/PageHeader";
import CalendarHeader from "../../TimesheetContainer/Calendar/CalendarHeader";
import Calendar from "./Calendar";
import {useDispatch, useSelector} from "react-redux";
import "./CRADisplayMobile.scss"
import SlidePageTransitionPortal from "../../common/SlidePageTransitionPortal";
import * as ValidationActions from "../../../actions/Validation";
import {monthInLetter} from "../../../utils/Utils";
import {useHistory} from "react-router-dom";
import Button from "../../TimesheetContainer/Forms/Event/Button";

const CRADisplayMobile = (props) => {
    const monthEntity = props.month
    const [showPortal, setShowPortal] = useState(false)
    const [showDetails, setShowDetails] = useState(false)
    const [comment, setComment] = useState("");
    const [isSubmitting, setSubmitting] = useState(false);
    const [groupedEvents, setGroupedEvents] = useState([]);
    const dispatch = useDispatch()
    const history = useHistory()
    const user = useSelector((state) => state.user.craUser);
    const me = useSelector((state) => state.user.me);
    const eventCodeRepo = EntityRepository().getRepository(EVENT_CODE_REPOSITORY);
    const [action, setAction] = useState("");

    const handleClose = () => {
        setShowPortal(false);
    };

    const handleCommentChange = (e) => {
        setComment(e.target.value);
    };

    const handleSubmit = () => {
        if (!isSubmitting) {
            setSubmitting(true);
            let json = {
                month: monthEntity.id,
                comment: comment,
                updated_at: moment.parseZone(monthEntity.updatedAt).format(
                    "YYYY-MM-DD[T]HH:mm:ss"
                ),
            };

            if (action === "validate") {
                json.action = "validated1";
            } else if (action === "refuse") {
                json.action = "non_submitted";
            }

            let body = JSON.stringify(json);
            dispatch(ValidationActions.postMonthAction(body)).then(() => {
                setSubmitting(false);
                history.push("/backend/activity")
            });
        }
    }

    const durationInHours = (durationInMinutes) => {
        let hoursDuration = "";

        let hour = Math.floor(durationInMinutes / 60);
        let minutes = durationInMinutes - hour * 60;

        hoursDuration = hour + "h" + (minutes < 10 ? "0" : "") + minutes;

        return hoursDuration;
    };

    const groupEventsByEventCode = (events) => {
        return Object.values(events).reduce((acc, event) => {
            const eventCode = eventCodeRepo.findWithoutHook(event.eventCode);
            const usedLabel = eventCode.type === "activity" ? event.eventCode : 'absence'

            if (!acc[usedLabel]) {
                acc[usedLabel] = {
                    ...eventCode,
                    daysCount: 0,
                    events: [],
                };
            }

            acc[usedLabel].daysCount += event.daysCount;
            acc[usedLabel].events.push(event);

            return acc;
        }, {});
    };

    const canValidateLevel1 = () => {
        if (
            me.hasRole("ROLE_ACTIVITY_ACCEPT_FIRST_LEVEL") &&
            monthEntity.absenceValidated &&
            !monthEntity.locked &&
            monthEntity.submitted &&
            user.manager === me.matricule
        ) {
            return true;
        }
        return false;
    };

    const validateCRA = () => {
        setShowPortal(true)
        setAction('validate')
    }

    const refuseCRA = () => {
        setShowPortal(true)
        setAction('refuse')
    }

    useEffect(() => {
        if (monthEntity.events) {
            setGroupedEvents(groupEventsByEventCode(monthEntity.events));
        }
    }, [monthEntity]);

    return user && <div id="page-container" className="timesheet-container">
        <div className="container">
            <PageHeader title={user.identity} type="timesheet"/>
            <CalendarHeader
                monthEntity={monthEntity}
                isRemoteEditing={true}
            />
            <Calendar month={monthEntity}/>
            <div id="day-details"></div>
            <div className="cra-validation-infos" onClick={() => setShowDetails(!showDetails)}>Informations
                complémentaires <i
                    className={"fas fa-chevron-double-" + (showDetails ? "up" : "down")}></i></div>
            <AnimatePresence>
                {showDetails && <motion.div className={"cra-validations-counters"} initial={{height: 0, opacity: 0}}
                                            animate={{height: 'auto', opacity: 1}}
                                            exit={{height: 0, opacity: 0}}
                                            transition={{duration: 0.3}}>
                    <div className="cra-validations-counters-line">
                        <span className="cra-validations-counters-description">Jours télétravaillés</span>
                        <span className="cra-validations-counters-count">{monthEntity.remoteDaysCount} J</span>
                    </div>
                    {user.displayWorkedDays &&
                        <div className="cra-validations-counters-line">
                            <span className="cra-validations-counters-description">Projection de jours travaillés au 31/12</span>
                            <span className="cra-validations-counters-count">{user.workedDays} J</span>
                        </div>
                    }
                    {user.displayQuota &&
                        <div className="cra-validations-counters-line">
                            <span
                                className="cra-validations-counters-description">Projection du contingent au 31/12</span>
                            <span className="cra-validations-counters-count">{user.quota} J</span>
                        </div>
                    }
                </motion.div>}
            </AnimatePresence>
            {canValidateLevel1() && <div className="cra-validation-actions">
                <Button className={"validate-btn"}
                        onClick={validateCRA}
                        text={"Valider"}
                />
                <Button className="refuse-btn"
                        onClick={refuseCRA}
                        text={"Refuser"}
                />
            </div>}
            <SlidePageTransitionPortal show={showPortal} handleClose={handleClose}>
                <PageHeader action={handleClose} title={user.firstname + ' ' + user.lastname} type="timesheet"/>
                <div className="cra-validation-mobile-header">{action === "refuse" ? "Refuser " : "Valider "} le CRA de
                    :
                </div>
                <div
                    className="cra-validation-mobile-event-code">{monthInLetter(monthEntity.month) + ' ' + monthEntity.year}</div>
                <div className="event-group-container">
                    {groupedEvents &&
                        Object.values(groupedEvents).map((group, key) => {
                            return <div key={key} className={`event-group-line`}>
                                <div
                                    className={"event-group-description" + (group.type === "activity" ? " activity-border" : " absence-border")}>{group.type === "activity" ? group.description : 'Absence'}</div>
                                <div className={"event-group-days-count"}>{group.daysCount} J</div>
                            </div>

                        })}
                    <div className={`event-group-line`}>
                        <div
                            className={"event-group-description extra-activity-border"}>Activités complémentaires
                        </div>
                        <div
                            className={"event-group-days-count"}>{durationInHours(monthEntity.extraActivitySummary.duration)}</div>
                    </div>
                </div>
                <textarea
                    className="validate-cra-mobile-comment-input"
                    placeholder="Commentaire"
                    onChange={(e) => handleCommentChange(e)}
                ></textarea>
                <div className="validation-mobile-actions">
                    <Button className={"validate-btn" + (isSubmitting ? ' disabled-btn' : '')}
                            onClick={handleSubmit}
                            text={"Confirmer"}
                            processable={true}
                    />
                    <Button className={"refuse-btn" + (isSubmitting ? ' disabled-btn' : '')}
                            onClick={handleClose}
                            text={"Annuler"}
                            processable={true}
                    />
                </div>
            </SlidePageTransitionPortal>
        </div>
    </div>
}

export default CRADisplayMobile
