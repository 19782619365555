import React, {Fragment, useEffect, useState} from "react";
import {FieldArray, Form, useFormikContext} from "formik";
import {changeOverlayContent} from "../../../events/OverlayEvents";
import moment from "moment";
import ExtraActivityDayLine from "../Forms/Event/StepActions/ExtraActivityDayLine";
import * as TimesheetActions from "actions/Timesheet";
import {useDispatch, useSelector} from "react-redux";
import FakeExtraDayLine from "./FakeExtraDayLine";
import ExtraDaySelector from "../../mobile/TimesheetMobile/ExtraDaySelector";
import ExtraActivitiesWeekSelection from "../../mobile/TimesheetMobile/ExtraActivitiesWeekSelection";
import Button from "../Forms/Event/Button";

const ExtraActivitiesForm = (props) => {
    const isMobile = useSelector((state) => state.common.isMobile)
    const extraDay = useSelector((state) => state.timesheet.extraDay)

    const {
        config,
        missingConfig,
        resetBtn
    } = props

    const {
        values,
        isSubmitting,
        setFieldValue,
        handleChange,
        handleBlur,
        initialValues,
        resetForm,
        setValues,
        submitForm
    } = useFormikContext()

    const [days, setDays] = useState([])
    const dispatch = useDispatch();

    const missingWeek = useSelector((state) => state.timesheet.entities.missingExtraActivityWeek)
    const overtimes = useSelector((state) => state.timesheet.entities.overtimeCodes)
    const eventCodes = useSelector((state) => state.timesheet.entities.eventCodes)
    const monthEntity = useSelector((state) => state.timesheet.entities.month)

    let complementaryIndex = 0;
    let constraintIndex = 0

    const handleCancel = () => {
        if (isMobile) {
            changeOverlayContent(<ExtraActivitiesWeekSelection monthEntity={monthEntity}/>)
        } else {
            dispatch(TimesheetActions.clearExtraActivityWeek());
            changeOverlayContent(null)
        }
    }

    const extractEntitiesFromResponse = (response, entityType, targetKey, sortKey, filterFunction) => {
        const entities = response.response.entities[targetKey];
        let tempEntities = [];
        if (entities) {
            tempEntities = Object.values(entities).filter(filterFunction);
        }
        tempEntities.sort((a, b) => {
            return b[sortKey] < a[sortKey] ? 1 : -1;
        });
        let finalEntities = [];
        tempEntities.forEach((element) => {
            finalEntities.push(new entityType(element));
        });

        return finalEntities
    }

    const emptyForm = () => {
        setValues(config.buildDefaultValues());
    }

    const buildDays = () => {
        let daysArray = [];

        if (config) {
            config.complementaries.map((day) => {
                daysArray.push({date: day.date, currentMonth: true})
            })
        }

        if (missingConfig) {
            missingConfig.complementaries.map((day) => {
                daysArray.push({date: day.date, currentMonth: false})
            })
        }

        daysArray.sort((a, b) => {
            return moment.parseZone(a.date) - moment.parseZone(b.date)
        })

        setDays(daysArray);
    }

    const canDisplayDay = (day) => {
        if (!isMobile) {
            return true
        }
        if (day !== undefined) {
            return extraDay.number === moment(day.date).format("D");
        }
        return false
    }

    const canDisplayType = (day, type) => {
        if (!isMobile) {
            return true
        }
        return extraDay.type === type;
    }

    useEffect(() => {
        // if (config && missingConfig) {
        buildDays()
        // }
    }, [config, missingConfig])

    const TotalHoursContainer = (props) => {
            let array;
            if (props.type === 'constraints') {
                array = values.constraints;
            } else {
                array = values.complementaries;
            }

            let duration = 0;
            let durationString;
            array.forEach((day) => {
                if (day.hours) {
                    day.hours.map((element) => {
                        if (element.start && element.end) {
                            let startTime = moment.parseZone(element.start, "HH:mm");
                            let endTime = moment.parseZone(element.end, "HH:mm");
                            if (endTime.format("HH:mm") === "00:00") {
                                duration += 24 * 60 * 60 * 1000 + endTime.diff(startTime);
                            } else {
                                duration += endTime.diff(startTime);
                            }
                        }
                    })
                }
            });

            let missingWeekArray;
            if (missingWeek) {
                if (props.type === 'constraints') {
                    missingWeekArray = missingWeek.constraints;
                } else {
                    missingWeekArray = missingWeek.complementaries;
                }
            }

            if (missingWeekArray) {
                missingWeekArray.forEach((day) => {
                    if (day.hours) {
                        day.hours.map((element) => {
                            if (element.start && element.end) {
                                let startTime = moment.parseZone(element.start, "HH:mm");
                                let endTime = moment.parseZone(element.end, "HH:mm");
                                if (endTime.format("HH:mm") === "00:00") {
                                    duration += 24 * 60 * 60 * 1000 + endTime.diff(startTime);
                                } else {
                                    duration += endTime.diff(startTime);
                                }
                            }
                        })
                    }
                });
            }

            let hours = parseInt(moment.duration(duration).asHours());
            let minutes = parseInt(moment.duration(duration).asMinutes()) % 60;


            if (!isNaN(hours) && !isNaN(minutes) && duration > 0) {
                if (minutes < 10) {
                    minutes = "0" + minutes;
                }

                durationString = hours + ":" + minutes;
            } else {
                durationString = "0:00";
            }

            return <>
                <div
                    className={`overtime-day-total-label-${props.type}-${(config.canDisplayEventCodesForComplementaries() || (!config.canDisplayComplementaries() && props.type === 'constraints')) ? 'large' : 'small'} ${(!config.canDisplayComplementaries() && props.type === 'constraints') ? 'no-complementaries-with-constraints' : ''}`}>
                    {isMobile ?
                        <div>Total {props.type === "constraints" ? "intervention en astreinte" : "activités complémentaires"}</div> :
                        <div>Total {props.type === "constraints" ? "heures d'intervention en astreinte" : "des plages d'activités complémentaires"}</div>}
                </div>
                <div className={`overtime-day-total-${props.type}`}>
                    {durationString}
                </div>
            </>
        }
    ;

    return <Form noValidate>
        {days.map((element, index) => {
                let day;
                if (values.complementaries.length > 0 && element) {
                    day = values.complementaries.find((complementary) => {
                        return element.date === complementary.date
                    });
                } else if (values.constraints.length > 0 && element) {
                    day = values.constraints.find((constraint) => {
                        return element.date === constraint.date
                    });
                }
                if (day) {
                    day.currentMonth = element.currentMonth
                }

                if (config.canComplementary(element.date)) {
                    complementaryIndex++
                }

                if (config.canConstraint(element.date)) {
                    constraintIndex++
                }

                return canDisplayDay(day) && <div className="extra-activities-form-line" key={index}>
                    <div
                        className="label">{moment.parseZone(element.date).format(isMobile ? 'dddd DD MMMM' : 'ddd DD MMM')}</div>

                    {(day && day.currentMonth && config.canDisplayComplementaries()) && config.canComplementary(element.date) && canDisplayType(day, 'complementary') ?
                        (<ExtraActivityDayLine
                            day={day}
                            id={complementaryIndex - 1}
                            key={'complementary.' + element.date}
                            values={day}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            path={"complementaries"}
                            missions={config.getComplementaryEventCodes(element.date)}
                            codes={config.getComplementaryOvertimeCodes(element.date)}
                            setFieldValue={setFieldValue}
                            config={config}
                        />) :
                        (config.canDisplayComplementaries() && canDisplayType(day, 'complementary') && <FakeExtraDayLine
                            key={'complementary.' + element.date}
                            element={element}
                            config={config}
                            missingWeek={missingWeek}
                            overtimes={overtimes}
                            eventCodes={eventCodes}
                            matchingDay={missingWeek?.complementaries.find(day => day.date === element.date)}
                            path={"complementaries"}
                        />)
                    }
                    {(day && config.canDisplayConstraints() && day.currentMonth) && config.canConstraint(element.date) && canDisplayType(day, 'constraint') ?
                        (<ExtraActivityDayLine
                            day={values.constraints[constraintIndex - 1]}
                            id={constraintIndex - 1}
                            key={'constraint.' + element.date}
                            values={values.constraints[constraintIndex - 1]}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            path={"constraints"}
                            codes={config.getConstraintOvertimeCodes(element.date)}
                            missions={config.getConstraintEventCode(element.date)}
                            missionSelected={config.getConstraintEventCode(element.date)}
                            config={config}
                        />) : (config.canDisplayConstraints() && canDisplayType(day, 'constraint') && <FakeExtraDayLine
                            key={'constraint.' + element.date}
                            element={element}
                            config={config}
                            missingWeek={missingWeek}
                            overtimes={overtimes}
                            eventCodes={eventCodes}
                            matchingDay={missingWeek?.constraints.find(day => day.date === element.date)}
                            path={"constraints"}
                        />)
                    }
                </div>
            }
        )}
        <div className="extra-form-bottom">
            {isMobile && <ExtraDaySelector config={config}/>}
            <div className={"overtime-day-total-line"}>
                {config.canDisplayComplementaries() && <TotalHoursContainer type={"complementaries"}/>}
                {config.canDisplayConstraints() && <TotalHoursContainer type={"constraints"}/>}
            </div>
            <div className="extra-activities-comment-label">
                <i className="far fa-comment-alt"></i> Commentaire
            </div>
            <textarea
                name="comment"
                value={values.comment}
                onChange={handleChange}
                className="extra-activities-comment"
            ></textarea>
            <div className="lb-confirm-send-actions">
                <div
                    onClick={handleCancel}
                    className="lb-confirm-send-cancel"
                >
                    Annuler
                </div>
                <Button
                    className="lb-confirm-send-confirm"
                    onClick={submitForm}
                    disabled={isSubmitting}
                    text={"Enregistrer"}
                    processable={true}
                />
                <button hidden type="button" ref={resetBtn} onClick={emptyForm}></button>
            </div>
        </div>
    </Form>
}

export default ExtraActivitiesForm
