import React, {useEffect, useState} from "react";
import firebase from 'firebase/compat/app';
import 'firebase/compat/messaging';
import {firebaseConfig} from "../../config/Firebase";
import * as Params from "config/Parameters";
import {isPWA, getOSInfo, getBrowserInfo} from "../../utils/Browser";
import {apiOptions} from "../../utils/Api";
import {canReceiveNotification} from "../../utils/NotificationConfig";

const NotificationStatus = (props) => {
    const [notificationPermission, setNotificationPermission] = useState("default");
    const [fcmToken, setFcmToken] = useState('');

    const hasNotification = 'Notification' in window

    firebase.initializeApp(firebaseConfig);
    const messaging = firebase.messaging();

    useEffect(() => {
        if (hasNotification) {
            setNotificationPermission(Notification.permission)
        }
    }, []);

    useEffect(() => {
        if (fcmToken) {
            const browserInfo = getBrowserInfo();
            const osInfo = getOSInfo();
            fetch(
                `${Params.API_ROOT}device`,
                apiOptions("POST", JSON.stringify({
                    token: fcmToken,
                    name: browserInfo.browserName + ' ' + browserInfo.browserVersion + (isPWA() ? ' PWA' : ''),
                    os: osInfo.osName
                }))
            )

        }
    }, [fcmToken])

    if ('permissions' in navigator) {
        navigator.permissions.query({name: 'notifications'}).then(function (notificationPerm) {
            notificationPerm.onchange = function () {
                console.log("User decided to change his seettings. New permission: " + notificationPerm.state);
                setNotificationPermission(notificationPerm.state)
            };
        });
    }

    const requestNotificationPermission = async () => {
        console.log(canReceiveNotification());
        if (localStorage.getItem("switchUser")) {
            alert("Vous ne pouvez pas activer les notifications en RunAs");
        }

        if (hasNotification && !localStorage.getItem("switchUser")) {
            const permission = await Notification.requestPermission()

            setNotificationPermission(permission);
            if (permission === 'granted') {
                // Récupérer le token FCM
                messaging.getToken().then((token) => {
                    console.log(token)
                    setFcmToken(token);
                }).catch(e => {
                    console.log(e)
                });

                navigator.serviceWorker.ready.then(async function (registration) {
                    const registrations = await navigator.serviceWorker.getRegistrations()
                    for (const reg of registrations) {
                        if (reg.scope.match('firebase')) {
                            const notification = await reg.showNotification('Activation des notifications', {
                                body: 'Les notifications ont bien été activées',
                                icon: '/favicon-32x32.png',
                            })
                        }
                    }
                })
            }
        }
    };

    const StatusContainer = ({children}) => {
        if (!hasNotification) {
            return ""
            // return <p>Votre navigateur n'est pas compatible avec les notifications</p>
        }
        switch (notificationPermission) {
            case "default":
            case "prompt":
                return <div className="status-notif status-notif-info"><p>Vous n'avez pas encore activé les
                    notifications dans votre navigateur</p>{children}</div>
            case "granted":
                return <div className="status-notif status-notif-success"><p>Les notifications sont activées dans votre
                    navigateur</p>{children}</div>
            case "denied":
                return <div className="status-notif status-notif-error"><p>Vous avez refusé les notifications dans votre
                    navigateur [Insérer un tuto en fonction du navigateur / OS]</p></div>
            default:
                return <></>
        }
    }

    return <div className="parameters-category-container">
        <StatusContainer>
            <button
                onClick={() => requestNotificationPermission()}>{notificationPermission === 'granted' ? 'Tester les notifications' : 'Autoriser les notifications'}</button>
        </StatusContainer>
    </div>
}
export default NotificationStatus
