import React, {Fragment} from "react";
import {useSelector} from "react-redux";
import moment from "moment";
import "moment/locale/fr";

import "./Step4Actions.scss";
import Button from "components/TimesheetContainer/Forms/Event/Button";
import {useState} from "react";
import {useEffect} from "react";

import {STEP4} from "components/TimesheetContainer/Forms/Event/Edit";
import {
    HOUR_INPUT,
    TICKET_INPUT,
} from "components/TimesheetContainer/Forms/Event/StepActions/Step3Overtime";
import {daysForOvertimeList} from "utils/Utils";

const Step4Actions = (props) => {
    // const selectedDays = useSelector((state) => state.timesheet.selectedDays);
    const {overtimeFormValues, constraintCategory, monthEntity} = props;
    const days = useSelector((state) => state.timesheet.entities.month.days);
    const [daysCount, setDaysCount] = useState(0);
    const isMobile = useSelector((state) => state.common.isMobile)

    useEffect(() => {
        let count = 0;
        let iterableDays = Object.values(days);
        let daysToAdd = [];
        iterableDays.forEach((element) => {
            if (element.worked || monthEntity.specialTime) {
                if (
                    moment.parseZone(element.dateAt).format("DD/MM/YY") >=
                    moment.parseZone(props.startAt).format("DD/MM/YY") &&
                    moment.parseZone(element.dateAt).format("DD/MM/YY") <=
                    moment.parseZone(props.endAt).format("DD/MM/YY")
                ) {
                    daysToAdd.push(element.id);
                }
            }
        });
        count = daysToAdd.length;

        if (props.selectedStartMoment === "pm") {
            count -= 0.5;
        }
        if (props.selectedEndMoment === "am") {
            count -= 0.5;
        }

        setDaysCount(count);
    }, [props.step]);

    const Step4Standard = () => {
        return (
            <div className="days-count">
                <div className="days-count-header">Total :</div>
                <div className="days-count-total">{daysCount} jour(s)</div>
            </div>
        );
    };

    const Step4Overtime = () => {
        const Header = () => {
            const HourHeader = () => {
                return (
                    <div className="overtime-days-container-header">
                        <div className="col1">Début :</div>
                        <div className="col2">Fin :</div>
                        <div className="col3">Nombre d'heures :</div>
                    </div>
                );
            };

            const TicketHeader = () => {
                return (
                    <div className="overtime-days-container-header">
                        <div className="tickets-header">
                            Nombre de tickets :
                        </div>
                    </div>
                );
            };

            let component;
            switch (overtimeFormValues.type) {
                case HOUR_INPUT:
                    component = <HourHeader/>;
                    break;
                case TICKET_INPUT:
                    component = <TicketHeader/>;
                    break;

                default:
                    component = "";
                    break;
            }

            return component;
        };

        const DayLineView = (props) => {
            const HourLine = (props) => {
                return (
                    <div className="hour-input">
                        {props.hour.start}
                        <div className="hour-separator">
                            <i className="far fa-chevron-right"></i>
                        </div>
                        {props.hour.end}
                    </div>
                );
            };

            const TotalHoursContainer = (props) => {
                let array = props.day.hours;
                let duration = 0;
                let durationString;
                array.forEach((element) => {
                    if (element) {
                        let startTime = moment.parseZone(
                            element.start,
                            "HH:mm"
                        );
                        let endTime = moment.parseZone(element.end, "HH:mm");
                        if (endTime.format("HH:mm") === "00:00") {
                            duration +=
                                24 * 60 * 60 * 1000 + endTime.diff(startTime);
                        } else {
                            duration += endTime.diff(startTime);
                        }
                    }
                });

                let hours = parseInt(moment.duration(duration).asHours());
                let minutes =
                    parseInt(moment.duration(duration).asMinutes()) % 60;

                if (!isNaN(hours) && !isNaN(minutes) && duration > 0) {
                    if (minutes < 10) {
                        minutes = "0" + minutes;
                    }

                    durationString = hours + ":" + minutes;
                } else {
                    durationString = "0:00";
                }

                return durationString;
            };

            return (
                <div className="overtime-day-line">
                    <div className="label">
                        {moment.parseZone(props.day.date).format(isMobile ? "ddd MM" : "dddd D MMMM")}
                    </div>
                    {overtimeFormValues.type === HOUR_INPUT ? (
                        <Fragment>
                            <div className="hour-line-container">
                                {props.day.hours.map((hour, key) => (
                                    <div className="hour-line" key={key}>
                                        <HourLine key={key} hour={hour}/>
                                    </div>
                                ))}
                            </div>
                            <div
                                className="total-hours-container"
                                style={{
                                    lineHeight:
                                        props.day.hours.length * 29 + "px",
                                }}
                            >
                                <TotalHoursContainer day={props.day}/>
                            </div>
                        </Fragment>
                    ) : (
                        ""
                    )}
                    {overtimeFormValues.type === TICKET_INPUT ? (
                        <div className="ticket-input-container">
                            {props.day.tickets}
                        </div>
                    ) : (
                        ""
                    )}
                </div>
            );
        };

        const TotalsContainer = () => {
            const TotalHours = () => {
                let duration = 0;
                let durationString;
                overtimeFormValues.days.forEach((hours) => {
                    let array = hours.hours;
                    array.forEach((element) => {
                        if (element) {
                            let startTime = moment.parseZone(
                                element.start,
                                "HH:mm"
                            );
                            let endTime = moment.parseZone(
                                element.end,
                                "HH:mm"
                            );
                            if (endTime.format("HH:mm") === "00:00") {
                                duration +=
                                    24 * 60 * 60 * 1000 +
                                    endTime.diff(startTime);
                            } else {
                                duration += endTime.diff(startTime);
                            }
                        }
                    });
                });

                let hours = parseInt(moment.duration(duration).asHours());
                let minutes =
                    parseInt(moment.duration(duration).asMinutes()) % 60;

                if (!isNaN(hours) && !isNaN(minutes) && duration > 0) {
                    if (minutes < 10) {
                        minutes = "0" + minutes;
                    }

                    durationString = hours + ":" + minutes;
                } else {
                    durationString = "0:00";
                }

                return <div className="input">{durationString}</div>;
            };

            const TotalTickets = () => {
                let ticketsCount = 0;
                overtimeFormValues.days.forEach((tickets) => {
                    if (!isNaN(tickets.tickets)) {
                        ticketsCount += tickets.tickets;
                    }
                });

                return <div className="input">{ticketsCount}</div>;
            };

            return (
                <div className="overtime-days-container-right">
                    <div
                        className={
                            "constraint-total" +
                            (constraintCategory === "intervention"
                                ? " hidden"
                                : "")
                        }
                    >
                        {overtimeFormValues.type !== HOUR_INPUT && (
                            <Fragment>
                                <div className="label">
                                    Total d'astreintes :{" "}
                                </div>
                                <div className="input-container">
                                    <div className="input">
                                        {overtimeFormValues.constraint_count}
                                    </div>
                                </div>
                            </Fragment>
                        )}
                    </div>
                    {overtimeFormValues.type === HOUR_INPUT ? (
                        <div className="hours-total">
                            <div className="label">Total d'heures :</div>
                            <div className="input-container">
                                <TotalHours/>
                            </div>
                        </div>
                    ) : (
                        ""
                    )}
                    {overtimeFormValues.type === TICKET_INPUT ? (
                        <div className="ticket-total">
                            <div className="label">Total de tickets :</div>
                            <div className="input-container">
                                <TotalTickets/>
                            </div>
                        </div>
                    ) : (
                        ""
                    )}
                </div>
            );
        };

        const Comment = () => {
            return (
                <div className="step4-comment">
                    <div className="step4-comment-label">
                        <i className="far fa-comment-alt"></i> Commentaire
                    </div>
                    <div className="step4-comment-input">
                        {overtimeFormValues.comment
                            ? overtimeFormValues.comment
                            : ""}
                    </div>
                </div>
            );
        };

        return (
            <div className="overtime-days-container">
                <div className="overtime-days-container-left">
                    <Header/>
                    {daysForOvertimeList(
                        overtimeFormValues,
                        overtimeFormValues.type
                    )
                        ? daysForOvertimeList(
                            overtimeFormValues,
                            overtimeFormValues.type
                        ).map((day, key) => {
                            return <DayLineView day={day} key={key}/>;
                        })
                        : ""}
                </div>
                <TotalsContainer/>
                {overtimeFormValues.comment && <Comment/>}
            </div>
        );
    };

    return (
        <div
            className={
                (props.step !== STEP4 ? "hidden " : "") +
                (props.selectedType !== "event" ? " overtime-actions " : "") +
                (constraintCategory === "intervention"
                    ? " intervention "
                    : " availability ") +
                "step4-actions"
            }
        >
            {props.selectedType === "event" ? (
                <Step4Standard/>
            ) : (
                <Step4Overtime/>
            )}

            <div className="btn-container">
                <Button
                    text={"Enregistrer"}
                    type="next"
                    onClick={() => props.handleSubmit()}
                    processable={true}
                />
            </div>
        </div>
    );
};

export default Step4Actions;
