import React, { useState, Fragment } from "react";
import TeamPlanningTableHeader from "components/Backend/TeamPlanningContainer/TeamPlanningTable/TeamPlanningTableHeader";
import TeamPlanningTableBody from "components/Backend/TeamPlanningContainer/TeamPlanningTable/TeamPlanningTableBody";
import {useDispatch, useSelector} from "react-redux";
import * as TimesheetActions from "actions/Timesheet";
import Slider from "../../../TimesheetContainer/Calendar/Slider";
import { monthInLetter } from "utils/Utils";
import moment from "moment";
import TeamPlanningHeaderMobile from "../../../mobile/TeamPlanningMobile/TeamPlanningHeaderMobile";

const TeamPlanningTable = (props) => {
    const {
        daysArray,
        me,
        managees,
        month,
        year,
        absences,
        holidays,
        openManager,
        closeManager,
        toggleManager
    } = props;

    const dispatch = useDispatch();
    const displayCounters = useSelector(state => state.timesheet.displayPlanningCounters)
    const displayWeekend = useSelector(state => state.timesheet.displayWeekend)

    const isMobile = useSelector((state) => state.common.isMobile)
    const handleClick = () => {
        dispatch(TimesheetActions.displayPlanningCounters(!displayCounters))
    }

    const handleClickWeekend = () => {
        dispatch(TimesheetActions.displayWeekend(!displayWeekend))
    }

    const firstDay = moment(daysArray[0]).parseZone()
    // const firstDayNumber = firstDay.format('DD')

    return (
        <div>

            {isMobile && (<TeamPlanningHeaderMobile day={firstDay} />)}

            <div className={"planning-lines-containers"}>
                <div className={"counters-btn"}>
                    <Slider
                        label="Afficher les compteurs de congés et de jours travaillés"
                        value={displayCounters}
                        onClick={handleClick}
                    />
                </div>

                <div className="weekend-btn">
                    <Slider
                        label="Afficher les weekends"
                        value={displayWeekend}
                        onClick={handleClickWeekend}
                    />
                </div>
                <TeamPlanningTableHeader daysArray={daysArray} />
                <TeamPlanningTableBody
                    me={me}
                    managees={managees}
                    month={month}
                    year={year}
                    absences={absences}
                    holidays={holidays}
                    openManager={openManager}
                    closeManager={closeManager}
                    toggleManager={toggleManager}
                    daysArray={daysArray}
                />
            </div>
        </div>
    );
};

export default TeamPlanningTable;
