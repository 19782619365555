import React, {useState, Fragment, useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import {useHistory, useParams} from "react-router";
import moment from "moment";
import "moment/locale/fr";

import * as UserActions from "actions/User";
import * as TimesheetActions from "actions/Timesheet";
import * as TeamPlanningActions from "actions/TeamPlanning";
import TeamPlanningTable from "components/Backend/TeamPlanningContainer/TeamPlanningTable/TeamPlanningTable";

import { setLoading } from "actions/Common";
import PageHeader from "../../common/PageHeader";
import Slider from "../../TimesheetContainer/Calendar/Slider";

const TeamPlanningContainer = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const me = useSelector((state) => state.user.me);
    const isMobile = useSelector((state) => state.common.isMobile)
    const managees = useSelector(
        (state) => state.teamPlanning.entities.managees
    );
    const openManagers = useSelector(
        (state) => state.teamPlanning.openManagers
    );

    const meHierarchy = useSelector((state) => state.user.meHierarchy);
    const absences = useSelector((state) => state.validation.absences);
    const [month, setMonth] = useState()
    const holidays = []
    const isLoading = useSelector((state) => state.common.isLoading);

    const openManager = (manager) => {
        let managers = [];
        managers.push(manager);

        dispatch(TeamPlanningActions.openManagers(managers));
    };

    const closeManager = (manager) => {
        const findManageesMatricules = (array, manager) => {
            if (meHierarchy[manager]) {
                for (let i = 0; i < meHierarchy[manager].length; i++) {
                    const element = meHierarchy[manager][i];
                    array.push(element);
                    findManageesMatricules(element);
                }
            }

            return array;
        };
        let managers = [];
        managers.push(manager);
        findManageesMatricules(managers, manager);

        dispatch(TeamPlanningActions.closeManagers(managers));
    };

    const toggleManager = (manager) => {
        if (openManagers.includes(manager)) {
            closeManager(manager)
        } else {
            openManager(manager)
        }
    }

    const [daysArray, setDaysArray] = useState([]);
    const {
        year = moment.parseZone().year(),
        week = moment.parseZone().isoWeek(),
    } = useParams();

    useEffect(() => {
        dispatch(setLoading(true));
        openManager(me.matricule);
        const dayCursor = moment()
            .isoWeekYear(year)
            .isoWeek(week)
            .startOf('week')
        const currentMonth = dayCursor.month() + 1
        setMonth(currentMonth)
        dispatch(UserActions.fetchMeHierarchy()).then((data) => {
            dispatch(setLoading(false));
        });
        let tempArray = [];
        let periodRange = [];
        dispatch(TeamPlanningActions.fetchTeamPlanningForWeek(year, week));
        let nextNumber;
        if (parseInt(week) < 52) {
            nextNumber = parseInt(week) + 1;
        } else {
            nextNumber = 1;
        }

        while (dayCursor.format("W") !== String(nextNumber)) {
            tempArray.push(dayCursor.format("YYYY-MM-DD"));
            dayCursor.add(1, "days");
        }
        setDaysArray(tempArray);
    }, []);

    useEffect(() => {
        if (!isMobile) {
            history.push("/backend/team-planning")
        }
    }, [isMobile]);

    return (
        <div className="team-planning-page">
            <PageHeader title="Planning d'équipe"  type="timesheet" />
            <div className="team-planning-container">
                {!isLoading && (
                    <Fragment>

                        <TeamPlanningTable
                            daysArray={daysArray}
                            me={me}
                            openManagers={openManagers}
                            managees={managees}
                            month={month}
                            year={year}
                            absences={absences}
                            holidays={holidays}
                            openManager={openManager}
                            closeManager={closeManager}
                            toggleManager={toggleManager}
                        />
                    </Fragment>
                )}
            </div>
        </div>
    );
};

export default TeamPlanningContainer;
