import React from "react";
import {useDispatch} from "react-redux";

import * as TimesheetActions from "actions/Timesheet";
import * as UserActions from "actions/User";
import Lightbox from "components/common/Lightbox";
import Button from "components/TimesheetContainer/Forms/Event/Button";
import {changeOverlayContent} from "events/OverlayEvents";
import moment from "moment";
import "moment/locale/fr";

import "./ConfirmDelete.scss";
import LightboxHeader from "../../../common/LightboxHeader";

const ConfirmDelete = (props) => {
    const dispatch = useDispatch();
    const eventToDelete = props.eventToDelete;
    const eventCodeToDelete = props.eventCodeToDelete;

    const EventCodeDescription = () => {
        let classes = "event-code";
        switch (eventCodeToDelete.type) {
            case "absence":
                classes += " event-code-absence";
                break;
            case "activity":
                classes += " event-code-activity";
                break;
            case "hoursNotWorked":
                classes += " event-code-hours-not-worked";
                break;

            default:
                break;
        }
        return <span className={classes}>{eventCodeToDelete.description}</span>;
    };

    const deleteEvent = () => {
        dispatch(TimesheetActions.deleteEvent(eventToDelete.id)).then(() => {
            changeOverlayContent(null);
            let eventToEditStartAt = moment.parseZone(eventToDelete.startAt);
            let currentMonth = eventToEditStartAt.month() + 1;
            let currentYear = eventToEditStartAt.year();
            return Promise.resolve(
                dispatch(TimesheetActions.clearDaysSelection())
            ).then(() => {
                dispatch(UserActions.fetchCurrentUser());
            });
        });
    };

    return (
        <Lightbox className="lb-w1200">
            <div className="lb-event-delete">
                <LightboxHeader>
                    <div className="lb-event-delete-header">Supprimer un évènement</div>
                </LightboxHeader>
                <div className="lb-event-delete-message">
                    Je confirme la suppression de la session :{" "}
                    <EventCodeDescription/>
                    {props.autofillable && props.mainActivity ? (
                        <div className="lb-event-delete-nota-bene">
                            NB : Par défaut, cette activité sera remplacée par
                            « {props.mainActivity.description} ».
                        </div>
                    ) : (
                        ""
                    )}
                </div>
                <div className="lb-event-delete-actions">
                    <Button
                        text={"Annuler"}
                        type="cancel"
                        onClick={() => changeOverlayContent(null)}
                    />
                    <Button
                        text={"Supprimer"}
                        type="next"
                        onClick={() => deleteEvent()}
                        processable={true}
                    />
                </div>
            </div>
        </Lightbox>
    );
};

export default ConfirmDelete;
