import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import "moment/locale/fr";
import { isoFormat } from "utils/Utils";

import ManageeLine from "components/Backend/TeamPlanningContainer/TeamPlanningTable/ManageeLine";

const ManageeGroup = (props) => {
    const EXCEPTIONS = ["0000004290", "0000004584", "0000004633"];
    const meHierarchy = useSelector((state) => state.user.meHierarchy);
    const managees = useSelector(
        (state) => state.teamPlanning.entities.managees
    );

    const {
        managee,
        manager,
        level,
        me,
        month,
        year,
        absences,
        holidays,
        openManager,
        closeManager,
        toggleManager,
        daysArray,
    } = props;
    const hasManagees = meHierarchy[managee];
    const formattedMonth = (month, year) => {
        let monthString = "";
        if (month < 10) {
            monthString = `0${month}`;
        } else {
            monthString = month;
        }

        return year + "-" + monthString;
    };

    return (
        <Fragment>
            {managees
                ?.filter((element) => {
                    return element.matricule === managee;
                })
                .filter((element) =>
                    formattedMonth(month, year)
                        ? (isoFormat(element.startAt) <=
                              isoFormat(new Date(year, month + 1, 0)) &&
                              isoFormat(element.endAt) >=
                                  formattedMonth(month, year) + "-01") ||
                          (isoFormat(element.startAt) <=
                              isoFormat(new Date(year, month + 1, 0)) &&
                              !element.endAt)
                        : true
                )
                .map((element) => {
                    let relevantAbsences = absences.filter((item) => {
                        return item.user === element.id;
                    });
                    return (
                        <ManageeLine
                            key={element.id}
                            user={element}
                            absences={relevantAbsences}
                            hasManagees={hasManagees}
                            level={level}
                            me={me}
                            holidays={holidays}
                            openManager={openManager}
                            closeManager={closeManager}
                            toggleManager={toggleManager}
                            daysArray={daysArray}
                            manager={manager}
                        />
                    );
                })}
            {hasManagees &&
                hasManagees.map((element, key) => {
                    if (EXCEPTIONS.indexOf(element) === -1) {
                        return (
                            <ManageeGroup
                                key={key}
                                managee={element}
                                manager={
                                    managees.filter((element) => {
                                        return element.matricule === managee;
                                    })[0]
                                }
                                level={level + 1}
                                month={month}
                                year={year}
                                absences={absences}
                                me={me}
                                holidays={holidays}
                                openManager={openManager}
                                closeManager={closeManager}
                                toggleManager={toggleManager}
                                daysArray={daysArray}
                            />
                        );
                    }

                    return false;
                })}
        </Fragment>
    );
};

export default ManageeGroup;
