import React, { useState } from "react";
import FilesContainer from "components/DocumentContainer/FilesContainer";
import "./MainDocuments.scss";

const MainDocuments = (props) => {
    const { welcomeBook, organizationChart } = props;

    return (
        <div className="main-documents-container">
            <img
                className="welcome-book-thumbnail"
                src={welcomeBook.thumbnailPicture}
                alt="Livret d'accueil"
            />
            <div className="main-documents-infos">
                <FilesContainer
                    title="Documents prinipaux"
                    files={[welcomeBook, organizationChart]}
                />
            </div>
        </div>
    );
};

export default MainDocuments;
