import React, {Fragment, useEffect, useState} from "react";
import {BrowserRouter, Redirect, useHistory, useLocation} from "react-router-dom";
import {Route, Switch} from "react-router";
import * as Sentry from "@sentry/react";
import moment from "moment";
import "moment/locale/fr";

import TimesheetNotifier from "components/TimesheetContainer/Calendar/TimesheetNotifier";
import LoginContainer from "components/LoginContainer/LoginContainer";
import ResetPasswordContainer from "components/ResetPasswordContainer/ResetPasswordContainer";
import Overlay from "components/common/Overlay";
import MobileOverlay from "components/common/MobileOverlay";
import Loader from "components/Loader";
import GDPRContainer from "components/GDPRContainer/GDPRContainer";
import {changeOverlayContent} from "events/OverlayEvents";

import "./App.scss";
import {setAppLoading, toggleMobile} from "actions/Common";
import {
    isAuthenticated,
    isDocumentsManager,
    isSwitching,
} from "utils/Utils";
import {useDispatch, useSelector} from "react-redux";
import * as UserActions from "actions/User";
import BackendRoutes from "./BackendRoutes";
import FrontendRoutes from "./FrontendRoutes";
import ForgotPasswordContainer from "./ResetPasswordContainer/ForgotPasswordContainer";
import TutorialContainer from "./TutorialContainer/TutorialContainer";
import TutorialLink from "components/common/TutorialLink";
import PasswordAboutToExpirePopUp from "components/HomepageContainer/PasswordAboutToExpirePopUp";
import Error from "./common/Error";
import {isMobile} from "../utils/Browser";

import firebase from 'firebase/compat/app';
import 'firebase/compat/messaging';
import {firebaseConfig} from "../config/Firebase";
import {onMessage} from "firebase/messaging";
import {
    buildNotificationFormPayload,
    canReceiveNotification,
    getUrlByReference,
    getNotificationConfig
} from "../utils/NotificationConfig";

const App = () => {
    const appLoading = useSelector((state) => state.common.appLoading);
    const [canManageDocuments, setCanManageDocuments] = useState(false);
    const me = useSelector((state) => state.user.me);
    const dispatch = useDispatch();
    const location = useLocation()

    if (process.env.NODE_ENV !== "development") {
        if (isAuthenticated()) {
            if (isSwitching()) {
                Sentry.setUser({impersonating: me.matricule});
            } else {
                Sentry.setUser({matricule: me.matricule});
            }
        }
    }

    /****** Notifications START ******/
    firebase.initializeApp(firebaseConfig);
    const messaging = firebase.messaging();
    onMessage(messaging, (payload) => {
        if (canReceiveNotification()) {
            const [title, options] = buildNotificationFormPayload(payload)

            navigator.serviceWorker.ready.then(async function (registration) {
                const registrations = await navigator.serviceWorker.getRegistrations()
                for (const reg of registrations) {
                    if (reg.scope.match('firebase')) {
                        const notification = await reg.showNotification(title, options);
                    }
                }
            })
        } else {
            console.log('notification silented')
        }
    });

    /****** Notifications END ******/

    useEffect(() => {
        const viewportMetaTag = document.querySelector('meta[name="viewport"]');
        viewportMetaTag.setAttribute(
            "content",
            "width=device-width, initial-scale=1, maximum-scale=1, minimum-scale=1, user-scalable=no"
        );
        const handleResize = () => {
            dispatch(toggleMobile(isMobile()));
        }
        window.addEventListener('resize', handleResize)

        handleResize()

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, []);

    useEffect(() => {
        if (
            isAuthenticated() &&
            !me.loaded &&
            window.location.pathname !== "/error" &&
            window.location.pathname !== "/maintenance"
        ) {
            dispatch(setAppLoading(true));
            dispatch(UserActions.fetchCurrentUser());
            // setLoading(false);
        }
        setCanManageDocuments(isDocumentsManager(me));

        const body = document.body;
        if (me.isContractor()) {
            body.classList.add('contractor-app');
        } else {
            body.classList.remove('contractor-app');
        }
    }, [me]);

    useEffect(() => {
        if (me.type != "internal" && me.type != "interim") {
            if (
                isAuthenticated() &&
                window.location.pathname !== "/error" &&
                window.location.pathname !== "/account"
            ) {
                if (
                    me.passwordExpirationDate &&
                    moment(me.passwordExpirationDate).format("YYYY-MM-DD") <=
                    moment().add("1", "month").format("YYYY-MM-DD") &&
                    process.env.NODE_ENV !== "development"
                ) {
                    changeOverlayContent(
                        <PasswordAboutToExpirePopUp me={me}/>
                    );
                }
            }
        }
    }, [location, me]);

    let routeChangeHandler

    useEffect(() => {
        console.log('route changes');
        changeOverlayContent(null);
    }, [location]);

    function Routes() {
        if (appLoading) {
            return <></>
        } else if (isAuthenticated()) {
            // return me.loaded && <RestrictedRoutes/>;
            return me.loaded ? <RestrictedRoutes/> : <div>Vous etes hors ligne</div>;
        } else {
            return <LoginRoute/>;
        }
    }

    function RestrictedRoutes() {
        return (
            <Switch>
                {(me.hasRole("ROLE_VALIDATION") || canManageDocuments) && (
                    <Route
                        path="/backend/*"
                        component={() => <BackendRoutes me={me}/>}
                    />
                )}
                <Route component={() => <FrontendRoutes me={me}/>}/>
            </Switch>
        );
    }

    function LoginRoute() {
        return (
            <Fragment>
                <Switch>
                    <Route path="/rgpd" component={() => <GDPRContainer/>}/>
                    <Route
                        path="/tutorial"
                        component={() => <TutorialContainer/>}
                    />

                    <Route
                        exact
                        path="/reset-password/:token"
                        component={() => <ResetPasswordContainer/>}
                    />
                    <Route
                        exact
                        path="/forgot-password/"
                        component={() => <ForgotPasswordContainer/>}
                    />
                    <Route
                        path="/login"
                        component={() => <LoginContainer/>}
                    />
                    <Route path="/*">
                        <Redirect to="/login"/>
                    </Route>
                </Switch>
                <TutorialLink/>
            </Fragment>
        );
    }

    return (
        // <AnimatePresence mode="wait" initial={false}>
        <div
            id="app-container"
            // key="contact"
            //         initial={{y: 1000}}
            //         animate={{y: 0}}
            //         exit={{y: -1000}}
            //         transition={{duration: 1, ease: [0.22, 1, 0.36, 1]}}
            // style={{
            //     height:
            //         isMobileOrTablet() &&
            //         isAuthenticated() &&
            //         mobileOverlayVisible &&
            //         "100%",
            // }}
            className={isAuthenticated() ? "" : "loging-page"}
        >
            {/*{installable && <button id="install" onClick={handleInstall}>Install</button>}*/}
            <>
                <Overlay/>
                <Fragment>
                    {/*<Loader/>*/}
                    <Route
                        path="/error"
                        component={() => (
                            <Error label="Une erreur est survenue, veuillez recharger la page."/>
                        )}
                    />
                    <Route
                        path="/maintenance"
                        component={() => (
                            <Error
                                label="Une maintenance est en cours, veuillez réessayer plus tard."
                                btnLabel="Recharger"
                                reloadTimeout={300000}
                            />
                        )}
                    />
                    <Routes/>
                    <TimesheetNotifier/>
                </Fragment>

                {process.env.NODE_ENV === "development" && (
                    <div id="dev-indicator">DEV</div>
                )}
                {process.env.REACT_APP_BUILD_ENV === "Preprod" && (
                    <div id="preprod-indicator">Preprod</div>
                )}
            </>

        </div>
        // </AnimatePresence>
    );
};

export default App;
